
import './App.css';
import LimeLP from "./LimeLP";
import MainPage from "./MainPage";
import {Route, Routes} from "react-router-dom";


function App() {
    return (
        <div className="App">

            <Routes>
                <Route path='/' element={<MainPage/>}/>
                <Route path='/lime' element={<LimeLP/>}/>
            </Routes>

        </div>
    );
}

export default App;
