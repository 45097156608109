import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

function capitalizeFirstLetter(string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const MainPage = () => {
    const [initKey, setInitKey] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const currentHost = window.location.host
        console.log(['www.lmzm24.co.za', 'lmzm24.co.za'].indexOf(currentHost))
        console.log(currentHost)
        if (['www.lmzm24.co.za', 'lmzm24.co.za'].indexOf(currentHost) >= 0) {
            window.location.href = '/lime'
        } else {
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const key = params.get('key');
        if (key) {
            if (key === '---autotargeting') {
                setInitKey('Промокод на скидку')
            } else {
                setInitKey(capitalizeFirstLetter(key));
            }
        } else {
            setInitKey('Промокод на скидку')
        }
    }, []);

    const handlePromoClick = () => {
        setTimeout(() => {
            window.ym(97696887, 'reachGoal', 'openTG')
            window.location.href = 'https://dhwnh.com/g/93fd4vbk6c82e8aa95afd68450d763/'
        }, 300)
    }
    if (loading) {
        return null
    }
    return (
        <div>
            <Helmet>
                <title>{`${initKey} | Лушчие промокоды у нас`}</title>
                <meta name="description" content="Лушчие промокоды у нас"/>
            </Helmet>
            <div className="container">
                <div className="platform">
                    <h2>{initKey}</h2>
                    <img src="https://storage.yandexcloud.net/ezflow-bucket/prmomos/sa-12.jpg" alt=""/>
                </div>

                <div className="text">
                    <h4>{initKey} на скидку 50%, успей получить количество ограничено!</h4>
                </div>
                <a className="promocod" id="promo"
                   href="https://t.me/+kmMB9d2jwQ0wMmQ6"
                   target="_blank"
                   onClick={() => handlePromoClick()}>
                    <h1>-50%</h1>
                    <h4>промокод действует<br/> на любой заказ</h4>
                </a>
                <div className="btn_class">
                    <a href="https://t.me/+kmMB9d2jwQ0wMmQ6" onClick={() => handlePromoClick()} id="link"
                       target="_blank" className="btn">
                        Получить
                    </a>
                    <h4>промоакция<br/> для всех клиентов 🎁</h4>
                </div>
                <h5>Для того, чтобы данный промо-код, вам необходимо подписаться на наш telegram-канал</h5>
            </div>
            <div className="footer">
                <h4>Все права защищены © 2024 - +7 (917) 330-98-30</h4>
                <h4>ИП Мухтаров А.Э. ИНН - 344599840809 ОГРНИП - 323344300037850</h4>
                <p>Волгоград, Волгоградская обл., 400054 ул. Лугопольская, д 74.</p>
            </div>
        </div>
    );
};

export default MainPage;