import React, {useState, useEffect} from 'react';
import './LimeLP.css';
import {Helmet} from "react-helmet";

function App() {
    const [offerURL, setOfferURL] = useState('https://trk.limetrackingsamx.com/click?pid=317&offer_id=3&sub1=={SUB1}&sub2=={SUB2}&sub3=={SUB3}&sub4=={SUB4}&sub5=={SUB5}&sub6=={SUB6}&sub7=={SUB7}&sub8=={SUB8}')

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sub1 = urlParams.get('sub1') || '';
        const sub2 = urlParams.get('sub2') || '';
        const sub3 = urlParams.get('sub3') || '';
        const sub4 = urlParams.get('sub4') || '';
        const sub5 = urlParams.get('sub5') || '';
        const sub6 = urlParams.get('sub6') || '';
        const sub7 = urlParams.get('sub7') || '';
        const sub8 = urlParams.get('sub8') || '';

        const newOfferURL = `https://trk.limetrackingsamx.com/click?pid=317&offer_id=3&sub1=${sub1}&sub2=${sub2}&sub3=${sub3}&sub4=${sub4}&sub5=${sub5}&sub6=${sub6}&sub7=${sub7}&sub8=${sub8}`;
        setOfferURL(newOfferURL);
        console.log(newOfferURL)
    }, []);

    return (
        <>
            <Helmet>
                <title>Get Loan Online</title>
                <meta name="description" content="Best Online Loan Offer"/>
            </Helmet>
            <div className='hero-wrapper'>
                <div className='v-image__image'>
                    <div className='image-overlay'>
                        <div className="content-wrapper">
                            <img src='https://lime24.co.za/img/logo.4445ab93.svg'/>
                            <h3 className="mobile-heading-h3 white--text">
                                Quick and Secure Payday Loans Online
                            </h3>
                            <p className="b-body-1 white--text">
                                From R500 to R8,000 loans with flexible terms up to 37 days. Get your payday
                                finance sorted with just a few required documents
                            </p>
                            <a href={offerURL} style={{zIndex: 999999}} className='get-money-button'>Get
                                Money</a>
                        </div>
                    </div>
                </div>

                <div className="home">
                    <div className="section-1">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12">
                                    <h3>Reliable Payday Loan Services</h3>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-12">
                                    <div className="home-card">
                                        <div className="v-card">

                                            <h4>Fast Loan Applications Online</h4>
                                            <p>Apply for swift payday loans online; approval leads to fast funding. Our
                                                efficient process is tailored for convenience, ensuring a hassle-free
                                                experience. Secure and reliable, our platform offers competitive rates
                                                and flexible terms. Get the financial support you need with minimal
                                                paperwork and quick turnaround. Trust us for your short-term borrowing
                                                needs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-12">
                                    <div className="home-card">
                                        <div className="v-card">

                                            <h4>Transparent Loan Terms</h4>
                                            <p>With Lime24, experience utmost transparency in payday loan conditions—no
                                                hidden fees, all costs vividly outlined. Our straightforward approach
                                                ensures you're fully informed, making your borrowing journey clear and
                                                predictable. Enjoy the integrity of transparent lending; every term,
                                                fee, and condition is clearly presented in your profile, empowering you
                                                to make informed financial decisions with confidence. Trust in our
                                                commitment to honesty and clarity, offering you a seamless and
                                                trustworthy lending experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <a href={offerURL} className='get-money-button'>Get Money</a>
                                <div className="col-sm-6 col-lg-4 col-12" style={{margin: "30px 0"}}>
                                    <div className="home-card">
                                        <div className="v-card">


                                            <p>Your data security is our top priority and we endeavour to provide
                                                unparalleled protection. We adhere to stringent global Visa and
                                                Mastercard standards, providing a safe platform for your personal and
                                                financial information. Our compliance with the National Credit Regulator
                                                and strict adherence to South African laws reiterate our commitment to
                                                maintaining the highest level of data integrity and privacy. You can
                                                trust in our robust security measures, designed to safeguard your
                                                information against unauthorized access and cyber threats. Experience
                                                peace of mind knowing that your data is handled with the utmost care and
                                                respect, backed by state-of-the-art technology and rigorous legal
                                                compliance. With Lime24, your information is not just protected; it's
                                                fortified.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12">
                                    <div className="d-flex align-start">

                                        <div>
                                            <h3>Fast Loan Applications Online</h3>
                                            <p>Apply for swift payday loans online; approval leads to fast funding. Our
                                                efficient process is tailored for convenience, ensuring a hassle-free
                                                experience. Secure and reliable, our platform offers competitive rates
                                                and flexible terms. Get the financial support you need with minimal
                                                paperwork and quick turnaround. Trust us for your short-term borrowing
                                                needs.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-start">

                                        <div>
                                            <h3>Transparent Loan Terms</h3>
                                            <p>With Lime24, experience utmost transparency in payday loan conditions—no
                                                hidden fees, all costs vividly outlined. Our straightforward approach
                                                ensures you're fully informed, making your borrowing journey clear and
                                                predictable. Enjoy the integrity of transparent lending; every term,
                                                fee, and condition is clearly presented in your profile, empowering you
                                                to make informed financial decisions with confidence. Trust in our
                                                commitment to honesty and clarity, offering you a seamless and
                                                trustworthy lending experience.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-start">

                                        <div>
                                            <h3>Secure Online Loans</h3>
                                            <p>Your data security is our top priority and we endeavour to provide
                                                unparalleled protection. We adhere to stringent global Visa and
                                                Mastercard standards, providing a safe platform for your personal and
                                                financial information. Our compliance with the National Credit Regulator
                                                and strict adherence to South African laws reiterate our commitment to
                                                maintaining the highest level of data integrity and privacy. You can
                                                trust in our robust security measures, designed to safeguard your
                                                information against unauthorized access and cyber threats. Experience
                                                peace of mind knowing that your data is handled with the utmost care and
                                                respect, backed by state-of-the-art technology and rigorous legal
                                                compliance. With Lime24, your information is not just protected; it's
                                                fortified.</p>
                                        </div>
                                    </div>
                                </div>
                                <a href={offerURL} className='get-money-button'>Get Money</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>


    )
        ;
}

export default App;